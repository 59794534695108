import { useState } from 'react';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import style from './Header.module.css';
import logo from './logo.png'

function Header({change, setChange}) {
    return (
        <>
        <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light shadow mb-3">
        <div class="container-fluid">
            <Link className='navbar-brand' onClick={() => {setChange(1 - change)}} to="/">
                <img src={logo} alt="" class="d-inline-block align-text-top logo-img" />
                    TJ Garden
            </Link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse px-3" data-toggle="collapse" data-target=".navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
                <Link data-toggle="collapse" data-target=".navbar-collapse" class={(window.location.pathname=='/' || window.location.pathname=='')?'nav-link active':'nav-link'} onClick={() => {setChange(1 - change)}} to="/">Home</Link>
                <Link data-toggle="collapse" data-target=".navbar-collapse" class={(window.location.pathname=='/products')?'nav-link active':'nav-link'} onClick={() => {setChange(1 - change)}} to="products">Products</Link>
                <Link data-toggle="collapse" data-target=".navbar-collapse" class={(window.location.pathname=='/about')?'nav-link active':'nav-link'} onClick={() => {setChange(1 - change)}} to="about">About Us</Link>
                <a class="nav-link" tabindex="-1">Contact Us</a>
            </div>
            </div>
        </div>
        </nav>
        <Outlet />
        </>
    );
  }
  
  export default Header;